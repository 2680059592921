import {vueInstance} from '@/main';

class Address {//用于省市县乡的处理
    constructor(code, name) {
        this.code = code;
        this.name = name;
        this.children = [];
    }
}

class AddressCasc {
    constructor() {
        this.provinceList = [];
        this.cityList = [];
        this.countyList = [];
        this.townList = [];

        this.props = {
            label: 'name',
            value: 'code',
            children: 'children'
        };
    }

    /*如果还没有地址，则先获取省份列表*/
    async getProvince() {
        this.clear();
        let res = await vueInstance.$http.post("sys/getAddress", {code: 0});
        if (res.state) {
            for (let item of res.data) {
                this.provinceList.push(new Address(item.code, item.name));
            }
        }
    }

    async handleItemChanged(val) {
        let code = val[val.length - 1];
        let res = await vueInstance.$http.post("sys/getAddress", {code: code});
        if (res.state) {
            let arr = [];
            let searchList = [];
            if (res.data.length) {
                for (let item of res.data) {
                    let addr = new Address(item.code, item.name)
                    if (3 == val.length) {
                        addr.children = null;
                    }
                    arr.push(addr);
                }
            } else {
                arr = null;
            }

            if (1 == val.length) {
                this.cityList = arr;
                searchList = this.provinceList;
            } else if (2 == val.length) {
                this.countyList = arr;
                searchList = this.cityList;
            } else if (3 == val.length) {
                this.townList = arr;
                searchList = this.countyList;
            }
            for (let item of searchList) {
                if (code == item.code) {
                    item.children = arr;
                    break;
                }
            }
        }
    }

    getAddressText(val) {
        console.log(val.length);
        if (!val) {
            return null;
        }

        let address = '';

        if (val.length == 4) {
            for (let item of this.provinceList) {

                if (item.code == val[0]) {
                    console.log(item);
                    address = address + item.name;
                    break;
                }
            }
            for (let item of this.cityList) {
                if (item.code == val[1]) {
                    console.log(item);
                    address = address + item.name;
                    break;
                }
            }
            for (let item of this.countyList) {
                if (item.code == val[2]) {
                    console.log(item);
                    address = address + item.name;
                    break;
                }
            }
            for (let item of this.townList) {
                if (item.code == val[3]) {
                    address = address + item.name;
                    break;
                }
            }

        }

        return address;


    }

    clear() {
        this.provinceList = [];
        this.cityList = [];
        this.countyList = [];
        this.townList = [];
    }

    //如果已经有选择了地址，则在初始化的时候，调用此接口即可
    async getAddress(provinceId, cityId, countyId, townId, pList) {
        let _that = this;
        if (pList) {
            if (cityId) {
                let res = await vueInstance.$http.post("sys/getAddress", {code: provinceId});
                if (res.state) {
                    for (let item of res.data) {
                        _that.cityList.push(new Address(item.code, item.name));
                    }
                    for (let item of _that.provinceList) {
                        if (item.code == provinceId) {//将市挂载到省列表下
                            item.children = _that.cityList;
                            break;
                        }
                    }
                    if (countyId) {
                        let res = await vueInstance.$http.post("sys/getAddress", {code: cityId});
                        if (res.state) {
                            for (let item of res.data) {
                                _that.countyList.push(new Address(item.code, item.name));
                            }
                            for (let item of _that.cityList) {//将县挂载到市下
                                if (item.code == cityId) {
                                    item.children = _that.countyList;
                                    break;
                                }
                            }
                            if (townId) {
                                let res = await vueInstance.$http.post("sys/getAddress", {code: countyId});
                                for (let item of res.data) {
                                    let add = new Address(item.code, item.name);
                                    add.children = null;
                                    _that.townList.push(add);
                                }
                                for (let item of _that.countyList) {//将乡镇挂载到县下
                                    if (item.code == countyId) {
                                        item.children = _that.townList;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            if (provinceId) {
                let res = await vueInstance.$http.post("sys/getAddress", {code: 0});
                if (res.state) {
                    for (let item of res.data) {
                        _that.provinceList.push(new Address(item.code, item.name));
                    }
                    if (cityId) {
                        let res = await vueInstance.$http.post("sys/getAddress", {code: provinceId});
                        if (res.state) {
                            for (let item of res.data) {
                                _that.cityList.push(new Address(item.code, item.name));
                            }
                            for (let item of _that.provinceList) {
                                if (item.code == provinceId) {//将市挂载到省列表下
                                    item.children = _that.cityList;
                                    break;
                                }
                            }
                            if (countyId) {
                                let res = await vueInstance.$http.post("sys/getAddress", {code: cityId});
                                if (res.state) {
                                    for (let item of res.data) {
                                        _that.countyList.push(new Address(item.code, item.name));
                                    }
                                    for (let item of _that.cityList) {//将县挂载到市下
                                        if (item.code == cityId) {
                                            item.children = _that.countyList;
                                            break;
                                        }
                                    }
                                    if (townId) {
                                        let res = await vueInstance.$http.post("sys/getAddress", {code: countyId});
                                        for (let item of res.data) {
                                            let add = new Address(item.code, item.name);
                                            add.children = null;
                                            _that.townList.push(add);
                                        }
                                        for (let item of _that.countyList) {//将乡镇挂载到县下
                                            if (item.code == countyId) {
                                                item.children = _that.townList;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

export {AddressCasc};