<template>
    <div>
        <div class="toolBar mBottom10">
            <el-button type="primary" size='small' plain @click="addOrg" :disabled="!global.isAdmin">新增
            </el-button>
            <el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
        </div>

        <el-row :gutter='10'>
            <el-col :md='6'>
                <el-card class="box-card orgItem">
                    <div slot="header">
                        <span>机构树形结构</span>
                    </div>
                    <div class="leftTree">
                        <el-tree :data="data" node-key="id" :expand-on-click-node="false"
                                 highlight-current
                                 check-strictly
                                 :check-strictly=true
                                 @node-click="handleNodeClick"
                                 :props="defaultProps">
                            <div class="custom-tree-node"
                                 style=' flex: 1;display: flex;align-items: center;justify-content: space-between;'
                                 slot-scope="{ node, data }">
                                <span v-if="node.disabled" style="color: #bbbbbb">{{ node.label }}</span>
                                <span v-else>{{node.label}}</span>
                                <!--<el-button type="text" size="mini" class='hkDanger' :disabled="!global.isAdmin" v-show="data.status==1"  @click="deleteOrg(node, data)">删除</el-button>-->
                            </div>
                        </el-tree>
                    </div>

                </el-card>
            </el-col>

            <el-col :md='18'>
                <el-card class="box-card orgItem">
                    <div slot="header">
                        <span>机构详情</span>
                    </div>
                    <el-form ref="form" :rules="formRules" :model="form" label-width="100px" size='small'
                             style='padding-right: 120px;'>
                        <el-form-item label="父机构名称：" size='small'>
                            <el-cascader
                                    v-model="form.testId"
                                    :props="selectProps"
                                    :options="options"
                                    filterable
                                    style="width: 100%">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="机构类型：" prop="type">
                            <el-select v-model="form.type" placeholder="请选择" class='maxWidth' size='small'>
                                <el-option v-for="item in global.DictionaryData['orgType']" :key="item.value"
                                           :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="机构名称：" prop="name">
                            <el-input v-model="form.name" size='small'></el-input>
                        </el-form-item>
                        <el-form-item label="机构地址：" prop="address">
                            <el-cascader
                                    style="width:100%"
                                    v-model="form.pcct"
                                    :options="address.provinceList"
                                    placeholder="请选择省市区"
                                    @expand-change="handleItemChange"
                                    :props="address.props">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="详细地址：" prop="detailAddress">
                            <el-input v-model="form.detailAddress" size='small'></el-input>
                        </el-form-item>
                        <el-form-item label="机构状态：" v-show="clickOrg==true" prop="status">
                            <el-select v-model="form.status" placeholder="请选择活动区域" class='maxWidth' size='small'>
                                <el-option v-for="item in global.DictionaryData['orgStatus']" :key="item.value"
                                           :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item style="float: right;margin-top: 20px">
                            <el-button size='small' :disabled="!global.isAdmin" v-show="clickOrg==true"
                                       @click="reset">重置
                            </el-button>
                            <el-button type="primary" :disabled="!global.isAdmin" v-show="clickOrg==true"
                                       size='small' @click="update">修改
                            </el-button>
                            <el-button type="primary" :disabled="!global.isAdmin" v-show="clickOrg==false"
                                       size='small' @click="success">保存
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {AddressCasc} from '@/utils/Address.js'

    class Form {
        constructor() {
            this.pcct = [];
            this.status = null;
            this.parentId = null;
            this.testId = [];
            this.parentId = null;
            this.type = null;
            this.name = null;
            this.detailAddress = null;
            this.province = null;
            this.city = null;
            this.county = null;
            this.town = null;
        }

        reset() {
            this.constructor();
        }
    }

    export default {
        data() {
            return {
                isResouceShow: 1,
                clickOrg: false,
                defaultProps: {children: 'children', label: 'name', id: "id", disabled: this.disabledFn},
                selectProps: {children: 'children', label: 'name', value: "id", checkStrictly: true},
                value: null,
                options: [],
                options2: [],
                data: [],
                form: new Form(),
                formRules: {
                    type: [{required: true, message: '机构类型是必须的', trigger: 'blur'}],
                    name: [{required: true, message: '机构名称是必须的', trigger: 'blur'}],
                },
                address: new AddressCasc(),
            }
        },
        computed: {

        },
        methods: {
            disabledFn(data, node) {
                if (data.status == -1) {  // 根据自己的节点数据条件设置判断，我只提供了个参考
                    return true;
                } else {
                    return false;
                }
            },
            addOrg() {
                this.clickOrg = false;
                this.form.reset();
            },
            refresh() {
                this.form.reset();
                this.load();
                console.log(this.address.provinceList)
            },
            deleteOrg(node, data) {
                this.$confirm('是否删除该机构, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.deleteOrganaztion(data.id);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
                /*let param={id:data.id};
                await this.$http.post('/sys/delOrg',param);
                this.load();*/
            },
            async deleteOrganaztion(id) {
                let param = {id: id};
                let res = await this.$http.post('/sys/delOrg', param);
                if (res.state) {
                    this.$message({message: '删除成功', type: 'success'});
                }
                this.form.reset();
                this.load();
            },
            resetForm(formName) {
                if(this.$refs[formName] !== undefined) {
                    this.$refs[formName].resetFields();
                }
            },
            handleNodeClick(data) {
                console.log(data);
                this.clickOrg = true;
                this.form.reset();
                this.resetForm('form');
                Object.assign(this.form, data);
                if (this.form.province) {
                    this.form.pcct = [this.form.province, this.form.city, this.form.county, this.form.town];
                    console.log(this.form.pcct);
                    this.address.getAddress(this.form.province, this.form.city, this.form.county, this.form.town, this.address.provinceList);
                    console.log("----")
                } else {
                    this.form.pcct = [];
                }
                if (data.parentId) {
                    this.form.testId = data.parentId
                }
            },
            reset() {
                this.form.reset();
            },
            update() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.updateOrganization();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async updateOrganization() {
                if (this.form.pcct.length > 0) { //地址转换
                    this.form.province = this.form.pcct[0];
                    this.form.city = this.form.pcct[1];
                    this.form.county = this.form.pcct[2];
                    this.form.town = this.form.pcct[3];
                }
                if (this.form.testId == []) {
                    this.form.parentId = 0;
                } else {
                    this.form.parentId = this.form.testId.pop() || 0;
                }
                let res = await this.$http.post('/sys/updOrg', this.form);
                if (res.state) {
                    this.$message({message: '修改成功', type: 'success'});
                }
                this.form.reset();
                this.load();
            },
            success() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.addOrganization();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async addOrganization() {
                if (this.form.pcct.length > 0) { //地址转换
                    this.form.province = this.form.pcct[0];
                    this.form.city = this.form.pcct[1];
                    this.form.county = this.form.pcct[2];
                    this.form.town = this.form.pcct[3];
                }
                if (this.form.testId == []) {
                    this.form.parentId = 0;
                } else {
                    this.form.parentId = this.form.testId.pop() || 0;
                }
                let res = await this.$http.post('/sys/addOrg', this.form);
                if (res.state) {
                    this.$message({message: '添加成功', type: 'success'});
                }
                this.form.reset();
                this.load();
            },
            async load() {
                let res = await this.$http.post('/sys/allOrgTree', {});
                if (res.state) {
                    this.data = res.data;
                    this.global.orgList = res.data;
                    this.options = res.data;
                }
            },

            handleItemChange(val) {//地址联动处理
                this.address.handleItemChanged(val);
            },
        },
        created() {
            this.load();
            this.address.getProvince();
        },
        mounted() {
            /*this.getoptions('orgType');
            this.getoptions('orgStatus');*/
        }
    }
</script>

<style>
    .orgItem {
        height: calc(100vh - 190px);
    }
    .leftTree {
        height: calc(100vh - 280px);
        overflow-y: scroll;
    }
    .leftTree::-webkit-scrollbar {
        display: none;
    }
</style>